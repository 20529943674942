import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BookingRequestPage = () => (
  <Layout pageInfo={{ pageName: "booking-request" }}>
    <SEO title="Booking request" />
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc4epcBdKeEFH-EXCCKkWqYNE1tXn-fMY_t7cTqQ6oJaaMajQ/viewform?embedded=true" width="640" height="3371" frameborder="0" marginheight="0" marginwidth="0" title="Booking-Request-2020">Loading…</iframe>
  </Layout>
)

export default BookingRequestPage
